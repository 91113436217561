@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {

	/* todo set in tw config!!!!!!!!!!!!!!!! */
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
		Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}


* {
	box-sizing: border-box;
}

.foxIconOff {
	text-shadow: 0 0 #bebebe !important;
	color: transparent !important;
}

/* main {
	flex-direction: column;
	height: calc(100vh - 86px);
} */

.nextui-navbar-collapse-wrapper li {
	margin-top: 0.75em;
}

.nextui-switch-circle {
	background: white !important;
}

.react-card-flip {
	width: 100%;
}


.gridjs-wrapper tr:nth-of-type(even) td {
	background: #fff;
 }
 
 .gridjs-wrapper tr:nth-of-type(odd) td {
	background: #efefef;
 }

